<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('手機APP') }}
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t('手機APP') }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <div class="main_content" :style="containerStyle">
                <div class="dltab_content">
                    <div class="dltab_detail">
                        <div class="xw_qrcode">
                            <vue-qr v-if="downloadUrl" :size="180" :margin="0" :auto-color="true" :dot-scale="1"
                                :text="downloadUrl" />
                        </div>
                        <div class="app_Title">
                            {{ $t('手機APP') }}
                        </div>
                        <div class="app_Text">
                            {{ $t("隨時隨地，立刻暢玩") }}
                        </div>

                        <div class="doenload_box">
                            {{ $t('IOS 使用者請使用Safari瀏覽器下載') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
    components: {
        VueQr
    },
    data() {
        return {
            tcContent: '',
            scale: 1,
            downloadUrl: '',
        }
    },
    computed: {
        containerStyle() {
            return {
                transform: `scale(${this.scale})`,
                transformOrigin: 'top left',
            };
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScale);
        this.updateScale(); // 初始化调用
        this.initDownUrl()
    },
    methods: {
        updateScale() {
            const containerWidth = 1000;
            const windowWidth = window.innerWidth;
            if (windowWidth < containerWidth) {
                this.scale = windowWidth / containerWidth;
            } else {
                this.scale = 1;
            }
        },
        initDownUrl(){
            let rule = this.$helper.get("rule");
            const ua = navigator.userAgent
            const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
			if (isSafari) {
				this.downloadUrl = rule.ios_download_url
			} else {
                this.downloadUrl = rule.android_download_url
			}
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScale);
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    max-width: 100vw;
    overflow: hidden;
    margin: 0 auto;
    @media (max-width:768px) {
        padding: 40px 8px;
    }
}

.main_content {
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    font-size: .8em;
    line-height: 1.7em;
    word-break: break-all;
    text-align: justify;
    color: #000;
    margin: 20px auto;
    width: 958px;
    height: 600px;
    border: 1px solid #d5d5d5;
    background: url('../../assets/images/xw/bg_app.jpg') no-repeat #fff;

    @media (max-width:768px) {}
}

.dltab_content {
    height: 400px;
    margin: 173px 10px 65px;
}

.dltab_detail {
    text-align: center;
}
.xw_qrcode{
    min-height: 180px;
}
.app_Title{
    margin-top: 32px;
    font-size: 60px;
    color: #404040;
    line-height: 65px;
}

.app_Text{
    font-size: 28px;
    margin-top: 18px;
    margin-bottom: 28px;
}

.doenload_box{
    box-sizing: border-box;
    text-align: center;
    margin: 0 auto;
    height: 40px;
    color: #fe0000;
    font-size: 16px;
    line-height: 40px;
    width: 280px;
    border-radius: 20px;
    border: 1px solid #fe0000;
}
</style>